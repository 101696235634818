import FetchUtils from '../../../utils/fetch_utils';
import './styles.scss';

class AddressOnFile {
  #csrfToken;

  provideLater(url) {
    fetch(url, {method: 'PUT', headers: {
      'x-csrf-token': this.#csrfToken
    }}).then(FetchUtils.checkResponseStatus).then((resp) => {
      return resp.json();
    }).then((data) => {
      document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {
        modalId: 'address-on-file-modal'
      }}));
      if (!data.success) {
        document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: 
          {content: `<div class="sm-modal-content">${data.message}</div>`}
        }));
      }
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  bindEvents() {
    const provideLaterBtn = document.querySelector('.js-address-on-file-provide-later');

    if (provideLaterBtn) {
      provideLaterBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.provideLater(provideLaterBtn.href);
        return false;
      });
    }
    
    const form = document.querySelector('.js-address-on-file-form');

    form.addEventListener('submit', (e) => {
      e.preventDefault();

      fetch(form.action, {
        method: form.method,
        'x-csrf-token': this.#csrfToken,
        body: new FormData(form)
      }).then(FetchUtils.checkResponseStatus).then((resp) => {
        return resp.json();
      }).then((data) => {
        if (data.success) {
          document.querySelector('.js-errors').innerHTML = '';
          document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {modalId: 'address-on-file-modal'}}));
          document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: 
            {content: '<div class="sm-modal-content">Thank you for submitting the company address.</div>'}
          }));
        } else {
          document.querySelector('.js-errors').innerHTML = data.errors;
        }
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });
    });

    const fields = Array.from(form.querySelectorAll("input[required], select[required]"));
    const submitButton = document.querySelector(".js-address-on-file-submit");

    function checkForm() {
      const allRequiredFilled = fields.every(field => field.value.trim() !== "");
      if (allRequiredFilled) {
        submitButton.classList.remove("disabled")
      } else {
        submitButton.classList.add("disabled")  
      }
    }

    fields.forEach(field => {
      field.addEventListener("input", checkForm);
    }); 
  }

  init() {
    this.#csrfToken = FetchUtils.CSRFToken();

    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const addressOnFileRequired = document.querySelector('.js-address-on-file-display-required');
  if (addressOnFileRequired) {
    fetch(addressOnFileRequired.dataset.url, {
      method: 'GET'
    }).then(FetchUtils.checkResponseStatus).then((resp) => {
      return resp.json();
    }).then((data) => {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: data.html,
        hideCloseButton: true,
        modalId: 'address-on-file-modal',
        onComplete: () => {
          const form = document.querySelector('.js-address-on-file-form');
          if (form) {
            new AddressOnFile().init();
          }
        }
      }}));
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }
});
